/** {*/
/*  user-select: none;*/
/*}*/

/*input, input:before, input:after {*/
/*  user-select: initial;*/
/*}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body, #root {
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

#root {
  position: relative;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fadein-exit {
  opacity: 1;
}

.fadein-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.MuiTableSortLabel-root {
  white-space: nowrap;
}